import React, { useRef } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

function ProductKeyFacts({ title, colorIcon, descriptions, gallery }) {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };
  return (
    <>
      <div className="row product-key-section-container">
        <div className="col-xl-6">
          <h1 className="titles-2 text-center">{title}</h1>
          <ul className="without-ul-ol align-key-facts">
            {descriptions.map((description) => (
              <li key={description.number}>
                <img
                  className="product-icons"
                  src={description.icon}
                  alt={description.title}
                ></img>
                <br></br>
                <h5 className="titles-3">{description.title}</h5>
                <div className="context-3">{description.description}</div>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-xl-6">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
          >
            {gallery.map((picture) => (
              <SwiperSlide key={picture.number}>
                <img
                  src={picture.urlPicture}
                  alt={picture.reference}
                  loading="lazy"
                />
                <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default ProductKeyFacts;
