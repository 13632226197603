import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import TopBar from "../Common/TopBarLink";
import productDescriptionData from "../mocks/productDescription.json";
import ProductHeader from "./Products/ProductHeader";
import ProductDescription from "./Products/ProductDescriptions";
import ProductKeyFacts from "./Products/ProductKeyFacts";
import ProductColorPalette from "./Products/ProductColorPalette";
import ProductAwardsHonours from "./Products/ProductAwardsHonours";

function ProductDetails(props) {
  console.log(props);
  const { productName } = useParams();
  const productDescription = productDescriptionData.find(
    (product) => product.product_name === productName
  );

  useEffect(() => {
    if (!productDescription) {
      return <div>Producto no encontrado.</div>;
    }

    window.scrollTo(0, 0);
  }, [productDescription]);

  return (
    <>
      <TopBar />
      <div className="product-container" data-scrollview="true">
        <div className="product-section" data-scrollview="true">
          <ProductHeader {...productDescription.header} />
        </div>
        <div className="product-section" data-scrollview="true">
          <ProductDescription {...productDescription.description} />
        </div>
        <div className="product-section" data-scrollview="true">
          <ProductKeyFacts {...productDescription.keyFacts} />
        </div>
        <div className="product-section" data-scrollview="true">
          <ProductColorPalette {...productDescription.colorPalette} />
        </div>
        <div className="product-section" data-scrollview="true">
          <ProductAwardsHonours {...productDescription.colorPalette} />
        </div>
      </div>
    </>
  );
}

export default ProductDetails;
