import React from "react";

function ProductDescription({
  backgroundImg,
  pictureProduct,
  logoProduct,
  title,
  description,
  strongPoints,
  productName,
}) {
  return (
    <>
      <div
        className="container-fluid product-details"
        style={{
          backgroundImage: `url(${backgroundImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="row">
          <div
            className="col"
          ><img className="product-description-picture" src={pictureProduct}></img></div>
          <div className="col product-description-text">
            <h1 className="titles-2">{title}</h1>
            <h5 className="context-1">{description}</h5>
            <ol>
              {strongPoints.map((point) => (
                <li className="context-2" key={point.number}>{point.point}</li>
              ))}
            </ol>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductDescription;
