import React from "react";
const bgrainforest1 = "./images/bg/bg-rainsforest1.png";
const bgflorecuador = "./images/bg/bg-florecuador.png";
const bgaboutus = "./images/bg/bg-aboutus.jpg";
const imgaboutus = "./images//about/about-me.jpg";
const About = () => {
  return (
    <>
      <div
        id="about"
        className="content"
        data-scrollview="true"
        style={{
          backgroundImage: `url(${bgaboutus})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div
          className="container"
          data-animation="true"
          data-animation-type="animate__fadeInDown"
          style={{ opacity: "100%", color: "white" }}
        >
          <h2 className="content-title" style={{ paddingBottom: "0.13rem" }}>
            Our Company
          </h2>
          <p
            className="content-desc"
            style={{ opacity: "100%", color: "white" }}
          >
            The process, from the very beginning until the moment they receive
            the flowers. For such endeavor and our exclusive high quality
            flowers,
            <br />
            we hold an excellent record in quality.
          </p>
          <div className="row">
            <div className="col-lg-4">
              <div className="about">
                <h3 className="mb-3">Our History</h3>
                <p>
                  MDB flowers&berries LLC, was established in 2001, exporting
                  worldwide exclusive high quality fresh flowers from Ecuador
                  all year around. In 2019 we expand our operation exporting
                  also from Ethiopia.
                </p>
                <p>
                  Ecuadorian flowers, are well known to have the highest quality
                  around the World, and to guarantee that, MDB flowers&berries
                  LLC performs quality controls, on farms and freighters.
                </p>
              </div>
              <img
                className="imgourcompany"
                src={imgaboutus}
                alt="About us"
                style={{
                  width: "71%",
                  marginLeft: "10%",
                  marginBlockEnd: "2%",
                  border: "3px solid",
                  borderRadius: "20%",
                }}
              />
            </div>
            <div className="col-lg-4">
              <h3 className="mb-3">Our Philosophy</h3>
              <div className="about-author">
                <div className="quote">
                  <i className="fa fa-quote-left"></i>
                  <h3>
                    "THINK HYPERICUM? <br />
                    <span>THINK ROMANCE!"</span>
                  </h3>
                  <i className="fa fa-quote-right"></i>
                </div>
                <div className="author">
                  <div className="image">
                    <img src="https://mdbstrg.blob.core.windows.net/mdbwebsite/db_website/product/flowers/hypericum/thinkhypericum.jpg" alt="Hypericum Varieties" />
                  </div>
                  <div className="info" style={{ color: "white" }}>
                    MDB
                    <small style={{ color: "white" }}>
                      flowers&berries LLC
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <h3 className="mb-3">Our Experience</h3>
              <div className="skills">
                <img
                  className="img-rainforest"
                  src={bgrainforest1}
                  style={{ width: "25%" }}
                  alt="logo-rainforest"
                ></img>
                <div
                  className="skills-name"
                  style={{ fontSize: "23px", color: "white" }}
                >
                  RainForest
                </div>
                <p className="bg-rainforest">
                  RainForest Certification, as defined by the Rainforest
                  Alliance, is a conservation tool whereby an independent, third
                  party awards a seal of approval guaranteeing consumers that
                  the products they are buying are the result of practices
                  carried out according to a specific set of criteria balancing
                  ecological, economic and social considerations.
                </p>
                <img
                  className="img-rainforest"
                  src={bgflorecuador}
                  style={{ width: "30%" }}
                  alt="logo-florecuador"
                ></img>
                <div
                  className="skills-name"
                  style={{ fontSize: "23px", color: "white" }}
                >
                  Flor Ecuador
                </div>
                <p className="bg-florecuador">
                  FlorEcuador® is the Socio-environmental Certification of
                  Expoflores, a program of self management which looks to
                  achieve compliance with social and environmental standards on
                  the part of the flower farms of Ecuador.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default About;
