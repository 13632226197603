import React from "react";
import TopBar from "../Common/TopBar";
import About from "./About";
import Customers from "./Customers";
import Catalog from "./Catalog";
import Contact from "./Contact";

const logo = "/images/logo/logo-mdb-white.svg";
const Home = () => {
  return (
    <>
      <TopBar />
      <div id="home" className="content has-bg home">
        <div
          className="content-bg"
          style={{ backgroundImage: `url(/images/bg/bg-home.jpg)` }}
          data-paroller="true"
          data-paroller-type="foreground"
          data-paroller-factor="-0.25"
        ></div>

        <div className="container home-content">
          <img
            src={logo}
            style={{ width: "50%", paddingTop: "25px" }}
            alt="logo-mdb"
          ></img>
          <p> MDB flowers&berries LLC </p>
        </div>
      </div>
      <About></About>
      <Catalog></Catalog>
      {/* <Customers></Customers> */}
      <Contact></Contact>
    </>
  );
};

export default Home;
