import React from "react";
import Gallery from "../components/Gallery/Gallery.js";

const Catalog = () => {
  return (
    <div id="catalog" className="content">
      <div
        className="content-bg"
        data-paroller-factor="0.5"
        data-paroller-factor-md="0.01"
        data-paroller-factor-xs="0.01"
      ></div>
      <div
        className="container"
        data-animation="true"
        data-animation-type="animate__fadeInUp"
      >
        <h2 className="content-title">Our Products</h2>
        <div>
          <Gallery />
        </div>
      </div>
    </div>
  );
};
export default Catalog;
