import React from "react";
const Footer = () => {
  return (
    <>
      <div id="footer" className="footer">
        <div className="container">
          <div className="footer-brand">
            <div><img className="footer-brand-logo" src="/images/logo/logo-mdb-white.svg" alt="logo-mdb" /></div>
            MDB flowers&berries LLC
          </div>
          <p>
            &copy; Copyright SoftBuilders IO - 2024 <br />
            An admin & front end theme with serious impact. Created by <a href="https://softbuilders.io">SoftBuilders</a>
          </p>
          {/* <p className="social-list">
            <a href="#"><i className="fab fa-facebook-f fa-fw"></i></a>
            <a href="#"><i className="fab fa-instagram fa-fw"></i></a>
            <a href="#"><i className="fab fa-twitter fa-fw"></i></a>
            <a href="#"><i className="fab fa-google-plus-g fa-fw"></i></a>
            <a href="#"><i className="fab fa-dribbble fa-fw"></i></a>
          </p> */}
        </div>
      </div>
    </>
  );
};

export default Footer;
