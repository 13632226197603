import productsData from "../../mocks/products.json";
import { Link } from 'react-router-dom';

const Gallery = () => {

  return (
    <div className="container px-2 py-6">
      <div className="row row-cols-1 row-cols-lg-4 align-items-stretch g-5 py-1">
        {productsData.map((product) => (
          <div className="col" key={product.id}>
            <Link to={`/products/${product.product_name}`}>
              <div
                id="gallery"
                className="card card-cover h-100 overflow-hidden rounded-4 shadow-lg"
                style={{ backgroundImage: `url(${product.thumbnail})` }}
              >
                <div className="d-flex flex-column h-100 p-5 pb-3 text-white text-background ">
                  <div>
                    <h5 className="card-text-style text-shadow-2 ">{product.product_title}</h5>
                    <div>{product.product_description}</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div >
  );
};

export default Gallery;
