import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const TopBar = () => {
  const handleMenuClick = () => {
    document.querySelector('.navbar-toggle').click();
  };
  
  useEffect(() => {
    const handleBodyClick = () => {
      const navbar = document.querySelector('.navbar-collapse');
      if (navbar.classList.contains('show')) {
        document.querySelector('.navbar-toggle').click();
      }
    };

    document.body.addEventListener('click', handleBodyClick);

    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, []);

  return (
    <>
      <div
        id="header"
        className="header navbar navbar-transparent navbar-inverse navbar-fixed-top navbar-expand-lg"
      >
        <div className="container">
          <a href="/" className="navbar-brand">
            <div className="brand-logo">
              <img src="/images/logo/logo-mdb-fl-white.png" alt="logo-mdb" />
            </div>
            <span className="brand-text">
              <span className="text-theme">MDB</span> flowers&berries LLC
            </span>
          </a>
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#header-navbar"
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <div className="collapse navbar-collapse" id="header-navbar">
            <ul className="nav navbar-nav navbar-end">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  HOME
                </a>
              </li>
              <li className="nav-item">
                <Link to="/">
                  <a
                    className="nav-link"
                    href="#about"
                    data-click="scroll-to-target"
                    onClick={handleMenuClick}
                  >
                    ABOUT US
                  </a>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/">
                  <a
                    className="nav-link"
                    href="#catalog"
                    data-click="scroll-to-target"
                    onClick={handleMenuClick}
                  >
                    PRODUCTS
                  </a>
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link to="/">
                  <a
                    className="nav-link"
                    href="#client"
                    data-click="scroll-to-target"
                    onClick={handleMenuClick}
                  >
                    CUSTOMERS
                  </a>
                </Link>
              </li> */}
              <li className="nav-item">
                <Link to="/">
                  <a
                    className="nav-link"
                    href="#contact"
                    data-click="scroll-to-target"
                    onClick={handleMenuClick}
                  >
                    CONTACT US
                  </a>
                </Link>
              </li>
              <li className="divider"></li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://app.businessonesoft.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleMenuClick}
                >
                  <img
                    src="./images/user/user-1.jpg"
                    className="user-img hide"
                    alt=""
                  />
                  <span className="d-none d-xl-inline">LOGIN</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopBar;
