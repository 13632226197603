import React from "react";

function ProductColorPalette({
  title,
  description,
  name,
  note,
  products,
  urlPicture,
  urlPantone,
}) {
  return (
    <>
      <div className="container-fluid product-palette">
        <h1 className="titles-2">{title}</h1>
        <p className="context-2">{description}</p>
        <div className="row">
          <div className="col-xl-12 col-sm-12">
            <img
              className="product-palette-picture "
              src={urlPicture}
              alt={name}
            />
          </div>
        </div>
        <p className="context-2">{note}</p>
        <div className="container px-5 py-4">
          <div className="row">
            {products.map((product) => (
              <div className="col-sm-6 col-xl-4 py-4" key={product.number}>
                <div className="card rounded-4 shadow-lg">
                  <img
                    class="card-img-top img-fluid"
                    src={product.url}
                    alt={product.name}
                  />
                  <div className="d-flex flex-column h-100 p-2 pb-3 text-white text-background-2 ">
                    <div>
                      <h5 className="card-text-style-2 text-shadow-2 ">
                        {product.name}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductColorPalette;
