import React, { useState } from "react";
import emailjs from "emailjs-com";
import { isValidEmail } from "../components/EmailValidator/EmailValidator.js";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
  const recaptchaRef = React.createRef();
  const [formData, setFormData] = useState({
    name: "",
    company: "",
    email: "",
    phone: "",
    message: "",
  });

  const [emailError, setEmailError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newFormData = { ...formData, [name]: value };
    setFormData(newFormData);
    updateFormValidity(newFormData);

    if (name === "email") {
      validateEmail(value);
    } else {
      updateFormValidity(newFormData);
    }
  };

  const validateEmail = (email) => {
    if (!isValidEmail(email)) {
      setEmailError("Invalid email format.");
    } else {
      setEmailError("");
      updateFormValidity(formData);
    }
  };

  const updateFormValidity = (newFormData) => {
    const areAllFieldsFilled = Object.values(newFormData).every(
      (value) => value.trim() !== ""
    );
    const isEmailValid = isValidEmail(newFormData.email);
    const isRecaptchaValid = recaptchaRef.current.getValue() !== "";
    setIsFormValid(areAllFieldsFilled && isEmailValid && isRecaptchaValid);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const recaptchaValue = recaptchaRef.current.getValue();

    if (!recaptchaValue) {
      console.log("Please complete the reCAPTCHA.");
      return;
    }

    const serviceId = "service_u0xrwfy";
    const templateId = "template_0v1ph9g";
    const userId = "7PLgPYR6nemYX8ENf";

    const templateParams = {
      from_name: formData.name,
      from_company: formData.company,
      from_phone: formData.phone,
      from_email: formData.email,
      message: formData.message,
      "g-recaptcha-response": recaptchaRef.current.getValue(),
    };

    try {
      const response = await emailjs.send(
        serviceId,
        templateId,
        templateParams,
        userId
      );
      console.log("Email sent successfully:", response);
    } catch (error) {
      console.error("Email sending error:", error);
    }

    recaptchaRef.current.reset();
    e.target.reset();
    setFormData({
      name: "",
      company: "",
      email: "",
      phone: "",
      message: "",
    });
    setIsFormValid(false);
  };

  return (
    <>
      <div id="contact" className="content bg-light" data-scrollview="true">
        <div className="container">
          <h2 className="content-title">Contact Us</h2>
          <p className="content-desc">
            In our floral world, every petal is a work of art, and each
            arrangement tells a story. At MDB flower&berries LLC, we are
            dedicated to bringing you the beauty of nature to every corner of
            your life. From the happiest of emotions to the most sentimental
            moments, our flowers become messengers of love, joy, and comfort.
          </p>
          <div className="row">
            <div
              className="col-lg-5"
              data-animation="true"
              data-animation-type="animate__fadeInLeft"
            >
              <h3>
                {" "}
                Contact us today and let us be a part of your most special
                moments.
              </h3>
              <p>
                We create emotional connections through nature and turn your
                ideas into extraordinary floral arrangements.
              </p>
              <p>
                <strong>MDB flower&berries LLC.</strong>
                <br />
                Quito - Ecuador
                <br />
                Miami - United States
              </p>
              <p>
                <span>Marcela Salcedo</span>
                <br />
                <span>Sales Director</span>
                <br />
                <a className="phone" href="tel:+593995140072">
                  +593 99 514 0072
                </a>
                <br />
                <a href="mailto:info@mdbflowers.com" className="text-theme">
                  info@mdbflowers.com
                </a>
              </p>
            </div>
            <div
              className="col-lg-7 form-col"
              data-animation="true"
              data-animation-type="animate__fadeInRight"
            >
              <form className="form-horizontal" onSubmit={handleSubmit}>
                <div className="row mb-3">
                  <label className="col-form-label col-lg-3 text-lg-right">
                    Name <span className="text-theme">*</span>
                  </label>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-form-label col-lg-3 text-lg-right">
                    Company <span className="text-theme">*</span>
                  </label>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                      name="company"
                      value={formData.company}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-form-label col-lg-3 text-lg-right">
                    Email <span className="text-theme">*</span>
                  </label>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {emailError && <p className="text-theme">{emailError}</p>}
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-form-label col-lg-3 text-lg-right">
                    Phone <span className="text-theme">*</span>
                  </label>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-form-label col-lg-3 text-lg-right">
                    Message <span className="text-theme">*</span>
                  </label>
                  <div className="col-lg-9">
                    <textarea
                      className="form-control"
                      rows="10"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-form-label col-lg-3 text-lg-right">
                    Are you a human? <span className="text-theme">*</span>
                  </label>
                  <div className="col-lg-9 text-left">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6LdHB1IqAAAAADDltsqBn1kCtXJvqyrvyqXeheaD"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-form-label col-lg-3 text-lg-right"></label>
                  <div className="col-lg-9 text-left">
                    <button
                      type="submit"
                      className="btn btn-theme btn-primary btn-block"
                      disabled={!isFormValid}
                    >
                      Send Message
                    </button>
                  </div>
                </div>
                <div className="row mb-3">
                  <label className="col-form-label col-lg-7 text-lg-right">
                    All fields are with (<span className="text-theme">*</span>)
                    are required.
                  </label>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Contact;
