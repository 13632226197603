import React from "react";
import "./App.css";
import Footer from "./Common/Footer";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./pages/Home";

import Whatsapp from "./components/WhatsApp/Whatsapp";
import ProductDetails from './pages/ProductDetails';

function App() {
  return (
    <Router>
      <div id="page-container" className="fade show">
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/products/:id" element={<ProductDetails />} /> */}
          <Route path="/products/:productName" element={<ProductDetails />} />
        </Routes>
        <Whatsapp></Whatsapp>
        <Footer />
      </div>

    </Router>
  );
}

export default App;
