import React from "react";

function ProductHeader({ backgroundImg, nameProductImg, phrase1, phrase2 }) {
  return (
    <>
      <div
        className="container-fluid"
        style={{
          backgroundImage: `url(${backgroundImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "50%",
        }}
      >
        <div className="container-fluid">
          <div className="container-fluid product-content-header-text">
            <div className="row">
              <div className="col-xl-6 col-sm-6 text-header-background">
                <div>
                  <h1 className="titles-1">{nameProductImg}</h1>
                </div>
                <div>
                  <p className="context-1">
                    {phrase1}
                    <br />
                    {phrase2}
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-sm-6"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductHeader;
